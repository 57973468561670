/* ---------- General Site Styles ---------- */
body {
  color: #fff;
  background-color: #0a0705;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: #ea6969;
}

a:hover {
  color: #ed9d9d;
  text-decoration: none;
}

h1, h2, h3, h4 {
  font-family: "Nanum Gothic", sans-serif;
}

.column {
  float: left;
  width: 50%;
}

.row {
  width: 100%;
  margin-right: 0px;
  margin-left: 0px;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-radius: 10px;
}

.collapse.show {
  display:flex;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
