/* ------- General Styles for All Pages ------- */

.topContainer {
  margin-left: auto;
  margin-right: auto;
  max-width: 1350px;
  padding: 0;
  width: calc(100vw - 100px);
}

.pageContainer {
  background: url("../img/background.jpeg") top center;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media only screen and (max-width : 990px) {
  .topContainer.expandOnSmallScreens {
    width: calc(100vw - 50px);
  }
}

/* ---------- Hero ---------- */

.hero {
  width: 100%;
  height: 100vh;
  background: url("../img/background.jpeg") top center;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  &:before {
    content: "";
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
  }

  .heroContainer {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }

  h1 {
    margin: 0 0 10px 0;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    text-transform: uppercase;
    color: #fff;
  }

  h2 {
    color: #eee;
    margin-bottom: 40px;
    font-size: 24px;
  }

  h3 {
    color: #b18f5f;
    font-size: 30px;
    padding: 10px 30px;
    margin-bottom: 30px;
  }
  
  @media (max-width: 768px) {
    h1 {
      font-size: 28px;
      line-height: 36px;
    }
    h2 {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 30px;
    }
    h3 {
      font-size: 22px;
    }
  }

  .heroButton {
    font-family: "Poppins", sans-serif;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 13px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 8px 30px 9px 30px;
    transition: 0.5s;
    background: rgba(255, 255, 255, 0.1);
    border: 2px solid #b18f5f;
    color: #fff;
    cursor: pointer;

    &:hover {
      background: #b18f5f;
      border: 2px solid #b18f5f;
    }
  }
}

@media (min-width: 1024px) {
  .hero {
    background-attachment: fixed;
  }
}

/* ---- Footer that displays tags under recipes and recipe cards ---- */

.entryFooter {
  a {
    color: #c3b6b8;
    transition: 0.3s;

    &:hover {
      color: #898390;
    }
  }

  .tagsContainer {
    line-height: 18px;
    position: relative;

    i {
      position: absolute;
      height: 100%;
      font-size: 18px;
    }

    .tags {
      list-style: none;
      display: inline;
      padding: 0 5px 0 20px;
      font-size: 12px;
  
      li {
        display: inline-block;
      }
    }
  }

  i {
    color: #9a8487;
    display: inline;
  }
}
