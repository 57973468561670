/* ---------- Header ---------- */

:global(#header) {
  transition: all 0.4s;
  z-index: 997;
  padding: 20px 0;

  &.headerScrolled, &:global(.header-unscrollable) {
    background: #040c06;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    padding: 12px 0;
  }

  .headerContainer {
    display: flex;

    .siteName {
      font-size: 32px;
      margin: 0;
      margin: 0 auto 0 0;
      padding: 0;
      line-height: 1;
      font-weight: 600;
      letter-spacing: 1px;
      text-transform: uppercase;
    
      a {
        color: #fff;
      }
    }
  }
}

@media (max-width: 992px) {
  :global(#header) .siteName {
    font-size: 28px;
  }
}

/* ---------- Nav Bar ---------- */
:global(.navbar) {
  padding: 0;
}

/* -- Wide Navigation -- */
.navMenu {
  ul {
    li {
      position: relative;
      white-space: nowrap;
      padding: 0 0 10px 25px;
    }
  }

  a {
    display: block;
    position: relative;
    color: #fff;
    transition: 0.3s;
    font-size: 15px;
    padding: 0 4px;
    letter-spacing: 0.4px;
    font-family: "Poppins", sans-serif;
  }

  a:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -6px;
    left: 0;
    background-color: #cbac80;
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out 0s;
  }

  a:hover:before, li:hover > a:before, .active > a:before {
    visibility: visible;
    transform: scaleX(1);
  }
}

/* -- Narrow Navigation -- */
:global(.navbar-toggler) {
  color: #ffffff;
  position: fixed;
  right: 15px;
  top: 15px;
  border: 0;
  background: none;
  font-size: 24px;
  transition: all 0.4s;
  outline: none !important;
  line-height: 1;
  cursor: pointer;
  text-align: right;
  height: 24px;
  padding: 0;
}

:global(.navbar-icon) {
  vertical-align: middle;
}