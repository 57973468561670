/* ---------- Footer ---------- */

.footer {
  color: #fff;
  font-size: 14px;
  background: #040c06;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);

  .footerContent {
    display: flex;
    padding: 24px 0;

    .copyright {
      padding-left: 30px;
    }
  }
}
